import Loader from "react-loader-spinner";

export type SpinnerTemplateProps = { size?: number; color?: string };

export const SpinnerTemplate = ({
  size,
  color,
}: SpinnerTemplateProps): JSX.Element => {
  return (
    <Loader type="Oval" color={color || "#FFFFFF"} height={size} width={size} />
  );
};
