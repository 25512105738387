import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import LogoColour1x from "./logo-colour-1x.png";
import LogoColour2x from "./logo-colour-2x.png";

export const LogoColourImage = ({
  height = 30,
  width = 137,
  highResolution = true,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? LogoColour2x : LogoColour1x}
      />
    </div>
  );
};
