import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useUser } from "@auth";
import { menuItems, MenuItemEntry } from "../menu-items";

export type SideBarLogicInterface = {
  menuItems: MenuItemEntry[];
  selected?: string | undefined;
  setSelected?: React.Dispatch<React.SetStateAction<string | undefined>>;
  activeAccordion?: string | undefined;
  setActiveAccordion?: React.Dispatch<React.SetStateAction<string | undefined>>;
  adminName: string;
};

export const useSideBarTemplate = (): SideBarLogicInterface => {
  const [, , pathname] = useLocation().pathname.split("/");
  const user = useUser();
  const [name] = user!.split("@");
  const adminName = name.charAt(0).toUpperCase() + name.slice(1);

  const [selected, setSelected] = useState<string | undefined>(pathname);
  const [activeAccordion, setActiveAccordion] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const accordionTitle = menuItems.find(({ items }) =>
      items?.find(({ link }) => link === pathname),
    )?.title;
    if (accordionTitle) {
      setActiveAccordion(accordionTitle);
    }
  }, [setActiveAccordion]);

  return {
    menuItems,
    selected,
    setSelected,
    activeAccordion,
    setActiveAccordion,
    adminName,
  };
};
