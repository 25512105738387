import { Link } from "react-router-dom";

export type SidebarItemTemplateProps = {
  name: string;
  icon: JSX.Element;
  setSelected?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setActiveAccordion?: React.Dispatch<React.SetStateAction<string | undefined>>;
  link: string;
  selected: boolean;
};

export const SidebarItemTemplate = ({
  name,
  icon,
  selected,
  link,
  setSelected,
  setActiveAccordion,
}: SidebarItemTemplateProps): JSX.Element => {
  return (
    <Link to={link!}>
      <div
        onClick={() => {
          setActiveAccordion!(undefined);
          setSelected!(link!);
        }}
        className={`${
          selected ? "bg-gray-100" : "bg-transparent"
        } w-full block hover:bg-primary-normal px-4 py-3 rounded-lg`}
      >
        <div className="flex items-center">
          <div>{icon}</div>
          <div className="ml-3">
            <h5
              className={`text-taupe-600 text-base ${
                selected ? "font-bold" : "font-normal"
              }`}
            >
              {name}
            </h5>
          </div>
        </div>
      </div>
    </Link>
  );
};
