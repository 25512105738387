import { AccordionItem, AccordionItemProps } from "./item-accordion";
import { SidebarItem, SidebarItemProps } from "./item-sidebar";
import { SignOutItem } from "./item-signout";

export type ItemProps =
  | ({ variant: "accordion" } & AccordionItemProps)
  | ({ variant: "sidebar" } & SidebarItemProps)
  | { variant: "signout" };

export const Item = (props: ItemProps): JSX.Element => {
  switch (props.variant) {
    case "accordion":
      return <AccordionItem {...props} />;
    case "sidebar":
      return <SidebarItem {...props} />;
    case "signout":
      return <SignOutItem />;
    default:
      throw new Error(`[ITEM ERROR] Invalid variant provided`);
  }
};
