import { Icon, Spinner } from "@atoms";
import { SignOutItemLogicInterface } from "./logic";

export type SignOutItemTemplateProps = SignOutItemLogicInterface;

export const SignOutItemTemplate = ({
  signingOut,
  signOut,
}: SignOutItemLogicInterface): JSX.Element => {
  return (
    <button
      type="button"
      onClick={signOut}
      className={`bg-transparent w-full block hover:bg-primary-normal px-4 py-3 rounded-lg`}
    >
      <div className="flex items-center">
        <div>
          {signingOut ? (
            <Spinner color="#000" size={24} />
          ) : (
            <Icon variant="backArrow" size={24} />
          )}
        </div>
        <div className="ml-3">
          <h5
            className={`text-taupe-600 text-base font-normal
              `}
          >
            Sign Out
          </h5>
        </div>
      </div>
    </button>
  );
};
