import { DashboardLayout, DashboardLayoutProps } from "./layout-dashboard";

export type LayoutProps = { variant: "authenticated" } & DashboardLayoutProps;

export const Layout = (props: LayoutProps): JSX.Element => {
  switch (props.variant) {
    case "authenticated":
      return <DashboardLayout {...props} />;
    default:
      throw new Error(`[LAYOUT ERROR]: Invalid variant provided`);
  }
};
