import Retool from "react-retool";

export interface RetoolModuleProps {
  moduleName: string;
}

// Module name is the name of the module as it appears in the Retool apps screen/URL. eg. "Manual_Transactions"
export function RetoolModuleTemplate({
  moduleName,
}: RetoolModuleProps): JSX.Element {
  // Will use the React App's environment to determine whether to connect Retool to our production or dev environment/database.
  const environment: string | undefined = process.env.REACT_APP_ENVIRONMENT;
  const envParam = { production: "production", dev: "staging" }[environment!];

  return (
    <div style={{ height: "100%", width: "100%", margin: 0, padding: 0 }}>
      <Retool
        url={`https://coinmiles.retool.com/apps/${moduleName}?_environment=${envParam}&_embed=true`}
        style={{ height: "100%", width: "100%", padding: 0 }}
      />
    </div>
  );
}
