import { Image } from "@atoms";
import { Item } from "@molecules";
import { SideBarLogicInterface } from "./logic";

export type SideBarTemplateProps = SideBarLogicInterface;

export const SideBarTemplate = ({
  menuItems,
  selected,
  setSelected,
  activeAccordion,
  setActiveAccordion,
  adminName,
}: SideBarTemplateProps): JSX.Element => {
  return (
    <div className="w-full h-full bg-primary-light px-4 py-6 border-r-4">
      <div className="mt-4 mb-2">
        <Image variant="logoColour" width={140} height={30} />
      </div>
      <div className="text-sm text-taupe-600 mb-4">
        <p>Welcome, {adminName}</p>
      </div>
      <div>
        {menuItems.map(({ title, icon, link, items, name }, index) => (
          <div key={index} className="my-2">
            {items ? (
              <Item
                variant="accordion"
                icon={
                  <Image
                    variant="menuItem"
                    type={icon}
                    width={32}
                    height={32}
                  />
                }
                name={title!}
                items={items}
                selected={selected}
                activeAccordion={activeAccordion}
                setSelected={setSelected}
                setActiveAccordion={setActiveAccordion}
              />
            ) : (
              <Item
                variant="sidebar"
                icon={
                  <Image
                    variant="menuItem"
                    type={icon}
                    width={32}
                    height={32}
                  />
                }
                name={name!}
                link={link!}
                selected={Boolean(selected == link)}
                setSelected={setSelected}
                setActiveAccordion={setActiveAccordion}
              />
            )}
          </div>
        ))}
        <div key={"sign-out"} className="my-2">
          <Item variant="signout" />
        </div>
      </div>
    </div>
  );
};
