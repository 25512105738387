import Amplify from "aws-amplify";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { AuthProvider, amplifyConfig } from "@auth";
import Router from "./router";
import "./index.css";

Amplify.configure(amplifyConfig);

ReactDOM.render(
  <StrictMode>
    <AuthProvider>
      <Router />
    </AuthProvider>
  </StrictMode>,
  document.getElementById("root"),
);
