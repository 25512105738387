import { useCallback, useContext, useEffect, useState } from "react";
import Auth from "@aws-amplify/auth";
import { CognitoUser as CognitoUserObject } from "amazon-cognito-identity-js";
import SecureLS from "secure-ls";

import { CognitoUser, SignInInput } from "./types";
import { AuthContext, AuthState } from "./auth-context";

const confirmAdmin = async (
  user: CognitoUserObject,
  { emailAddress, password }: SignInInput,
): Promise<CognitoUser> => {
  const [name] = emailAddress.split("@");
  const given_name = name.charAt(0).toUpperCase() + name.slice(1);
  return Auth.completeNewPassword(user, password, {
    given_name,
    family_name: "Admin",
  });
};

const encryptedLocalStorage = new SecureLS();

/* useAuth is used only to provide auth values to the app's global context AuthProvider. */
export function useAuth(): AuthState {
  const [adminUser, setAdminUser] = useState<string | undefined>(
    encryptedLocalStorage.get("admin-user"),
  );

  useEffect(() => {
    let active = true;
    (async () => {
      try {
        if (active) await Auth.currentAuthenticatedUser();
      } catch (error) {
        if (active) {
          setAdminUser(undefined);
          encryptedLocalStorage.remove("admin-user");
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [setAdminUser]);

  const signIn = useCallback(
    async ({ emailAddress, password }: SignInInput) => {
      const user = await Auth.signIn(emailAddress, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        const { username } = await confirmAdmin(user, {
          emailAddress,
          password,
        });
        encryptedLocalStorage.set("admin-user", username);
        setAdminUser(username);
      } else {
        encryptedLocalStorage.set("admin-user", user.username);
        setAdminUser(user.username);
      }
    },
    [setAdminUser],
  );

  const signOut = useCallback(async () => {
    await Auth.signOut();
    setAdminUser(undefined);
    encryptedLocalStorage.remove("admin-user");
  }, [setAdminUser]);

  return { adminUser, signIn, signOut };
}

/* Use the below hooks as needed for auth operations through the app. */
export function useUser(): string | undefined {
  const { adminUser } = useContext(AuthContext);
  if (!adminUser) {
    return undefined;
  } else {
    return adminUser;
  }
}

export function useSignIn(): (input: SignInInput) => Promise<any> {
  return useContext(AuthContext).signIn;
}

export function useSignOut(): () => Promise<void> {
  return useContext(AuthContext).signOut;
}
