import { SideBar } from "./bar-side";

export type BarProps = {
  variant: "side";
};

export const Bar = (props: BarProps): JSX.Element => {
  switch (props.variant) {
    case "side":
      return <SideBar />;
    default:
      throw new Error(`[BAR] Invalid variant provided`);
  }
};
