import {
  ChevronDownIcon,
  ChevronDownIconProps,
  BackArrowIcon,
  BackArrowIconProps,
} from "./template";

export type IconVariantType = "chevronDown" | "backArrow";

export type IconProps =
  | ({ variant: "chevronDown" } & ChevronDownIconProps)
  | ({ variant: "backArrow" } & BackArrowIconProps);

export const Icon = (props: IconProps): JSX.Element => {
  const { variant } = props;
  switch (props.variant) {
    case "chevronDown":
      return <ChevronDownIcon {...props} />;
    case "backArrow":
      return <BackArrowIcon {...props} />;

    default:
      throw new Error(`Invalid Icon variant [${variant}] used`);
  }
};
