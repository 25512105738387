import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSignOut } from "@auth";

export type SignOutItemLogicInterface = {
  signingOut: boolean;
  signOut: () => Promise<void>;
};

export const useSignOutItem = (): SignOutItemLogicInterface => {
  const [signingOut, setSigningOut] = useState<boolean>(false);
  const navigate = useNavigate();
  const signOutFunc = useSignOut();

  const signOut = async (): Promise<void> => {
    try {
      setSigningOut(true);
      setTimeout(async () => {
        await signOutFunc();
        navigate("/sign-in");
      }, 1000);
    } catch (error) {
      setSigningOut(false);
    }
  };

  return { signOut, signingOut };
};
