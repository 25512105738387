import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Icon, Image } from "@atoms";
import { MenuItemEntry } from "@organisms";

export type AccordionItemTemplateProps = {
  name: string;
  icon: JSX.Element;
  items: MenuItemEntry[];
  selected?: string | undefined;
  setSelected?: React.Dispatch<React.SetStateAction<string | undefined>>;
  activeAccordion?: string | undefined;
  setActiveAccordion?: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const AccordionItemTemplate = ({
  name,
  icon,
  items,
  selected,
  setSelected,
  activeAccordion,
  setActiveAccordion,
}: AccordionItemTemplateProps): JSX.Element => {
  const [height, setHeight] = useState("0px");
  const [rotate, setRotate] = useState(
    "transform duration-700 ease rotate-180",
  );

  const contentSpace = useRef(null);
  const active = Boolean(activeAccordion == name);

  useEffect(() => {
    if (active) {
      openAccordion();
    } else {
      closeAccordion();
    }
  }, [activeAccordion]);

  const openAccordion = () => {
    setHeight(`${(contentSpace as any).current.scrollHeight}px`);
    setRotate("transform duration-700 ease");
  };
  function closeAccordion() {
    setHeight("0px");
    setRotate("transform duration-700 ease rotate-180");
  }

  return (
    <>
      <button
        type="button"
        className={`bg-transparent w-full block hover:bg-primary-normal px-4 py-3 ${
          active ? "rounded-t-lg" : "rounded-lg"
        } duration-700 ease-in-out ${active && "bg-gray-100"}`}
        onClick={() => {
          !active && setSelected!(name);
          setActiveAccordion!(name);
        }}
      >
        <div className="flex justify-between">
          <div className="flex items-center">
            <div>{icon}</div>
            <div className="ml-3">
              <h5
                className={`text-taupe-600 text-base ${
                  active ? "font-bold" : "font-normal"
                }`}
              >
                {name}
              </h5>
            </div>
          </div>
          <div className="flex items-center">
            <Icon
              className={`${rotate} inline-block`}
              variant="chevronDown"
              width={16}
              height={16}
            />
          </div>
        </div>
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className={`overflow-hidden h-auto duration-700 ease-in-out rounded-b-lg ${
          active && "bg-gray-100"
        }`}
      >
        <div className="px-4 py-3">
          {items.map(({ name, link, icon }) => {
            return (
              <Link
                key={name}
                to={link!}
                onClick={() => {
                  setSelected!(link);
                }}
              >
                <div
                  className={`cursor-pointer mb-2 hover:bg-primary-normal rounded-lg px-4 py-3 ${
                    selected === link ? "font-bold" : "font-normal"
                  }`}
                >
                  <div key={name} className="flex items-center">
                    <div>
                      {
                        <Image
                          variant="menuItem"
                          type={icon}
                          width={32}
                          height={32}
                        />
                      }
                    </div>
                    <div className="ml-3 text-taupe-600 text-base">{name}</div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};
