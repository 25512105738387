import { BackgroundImage } from "./image-background";
import { LogoColourImage } from "./image-logo-colour";
import { LogoWhiteImage } from "./image-logo-white";
import { MenuItemImage, MenuItemProps } from "./image-menu-item";

type HighResolutionProps = {
  highResolution?: boolean;
};

type RectangularIconProps = {
  width: number | string;
  height: number | string;
} & HighResolutionProps;

export type ImageVariantType =
  | "background"
  | "logoColour"
  | "logoWhite"
  | "menuItem";

export type ImageProps =
  | ({ variant: "background" } & RectangularIconProps)
  | ({ variant: "logoColour" } & RectangularIconProps)
  | ({ variant: "logoWhite" } & RectangularIconProps)
  | ({ variant: "menuItem" } & MenuItemProps);

export const Image = (props: ImageProps): JSX.Element => {
  const { variant } = props;
  switch (props.variant) {
    case "background":
      return <BackgroundImage {...props} />;
    case "logoColour":
      return <LogoColourImage {...props} />;
    case "logoWhite":
      return <LogoWhiteImage {...props} />;
    case "menuItem":
      return <MenuItemImage {...props} />;
    default:
      throw new Error(`Invalid Image variant [${variant}] provided`);
  }
};
