import { FormEvent } from "react";
import { Button, Image, Input } from "@atoms";

export interface FormValues {
  emailAddress: string;
  password: string;
}

export interface FormErrorValues {
  emailAddress: string | undefined;
  password: string | undefined;
}

export type SignInPageTemplateProps = {
  values: FormValues;
  errors: FormErrorValues;
  loading: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
};

export const SignInPageTemplate: React.FC<SignInPageTemplateProps> = ({
  values,
  errors,
  loading,
  handleInputChange,
  handleSubmit,
}: SignInPageTemplateProps): JSX.Element => {
  const { emailAddress, password } = values;
  const { emailAddress: emailAddressError, password: passwordError } = errors;

  return (
    <div className="min-h-screen flex">
      <div className="relative w-full min-h-screen w-4/10 flex justify-center items-center">
        <form
          className="text-center flex flex-col items-center max-w-xs mx-auto px-4"
          onSubmit={handleSubmit}
        >
          <div className="px-3 mb-6">
            <Image variant="logoColour" width={135} height={30} />
          </div>
          <div className="px-3 mb-6">
            <div className="mb-2">
              <h3 className="text-2xl font-black">Sign-in to your Account</h3>
            </div>
          </div>
          <div className="w-full">
            <div className="mb-3">
              <Input
                name="emailAddress"
                variant="text"
                onChange={handleInputChange}
                placeholder="Email address"
                value={emailAddress}
                errorMessage={emailAddressError}
              />
            </div>
            <div className="mb-3">
              <Input
                name="password"
                variant="password"
                onChange={handleInputChange}
                placeholder="Password"
                value={password}
                errorMessage={passwordError}
              />
            </div>
          </div>
          <div className="mb-4 w-full sm:mb-4">
            <div>
              <Button
                type="submit"
                variant="primary"
                label="Sign In"
                stretch
                color="red"
                brightness={500}
                loading={loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
