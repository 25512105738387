import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { useUser } from "@auth";
import { AuthenticatedRouter, SignInPage } from "@pages";

const Router = (): JSX.Element => {
  const user = useUser();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="authenticated/*"
          element={user ? <AuthenticatedRouter /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="sign-in"
          element={
            user ? <Navigate to="/authenticated/dashboard" /> : <SignInPage />
          }
        />
        <Route
          path="*"
          element={user ? <AuthenticatedRouter /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/"
          element={user ? <AuthenticatedRouter /> : <Navigate to="/sign-in" />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
