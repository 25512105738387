import { Bar } from "@organisms";

export type DashboardLayoutTemplateProps = {
  children: JSX.Element;
};

export const DashboardLayoutTemplate = ({
  children,
}: DashboardLayoutTemplateProps): JSX.Element => {
  return (
    <div
      style={{ gridTemplateColumns: "240px 1fr" }}
      className="grid w-screen min-h-screen"
    >
      <div className="h-full">
        <Bar variant="side" />
      </div>
      <div className="h-full bg-white">{children}</div>
    </div>
  );
};
