export type SquareIconProps = {
  size: number;
  className?: string;
};

export type RectangularIconProps = {
  width: number;
  height: number;
  className?: string;
};

/*
  Down Chevron Icon
*/
export type ChevronDownIconProps = RectangularIconProps;

export const ChevronDownIcon = ({
  width,
  height,
  className,
}: ChevronDownIconProps): JSX.Element => {
  return (
    <svg
      display="block"
      width={width}
      height={height}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 1L5 5L9 1"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

/*
  Back Arrow Icon
*/
export type BackArrowIconProps = SquareIconProps;

export const BackArrowIcon = ({
  size = 24,
}: BackArrowIconProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 12H5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19L5 12L12 5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
